:root {
  --color-primary: var(--primary-500);
}

.bi-incapsulation {
  @import 'styles/scss/style';
  @import 'assets/css/variables';

  @import '../../../node_modules/datatables.net-dt/css/jquery.dataTables';
  @import '../../../node_modules/datatables.net-buttons-dt/css/buttons.dataTables';
  @import '../../../node_modules/ngx-lightbox/lightbox';
  @import '../../../node_modules/dragula/dist/dragula';
  @import '../../../node_modules/nouislider/distribute/nouislider.min';
  @import '../../../node_modules/famfamfam-flags/dist/sprite/famfamfam-flags.min';
  @import '../../../node_modules/codemirror/lib/codemirror';
  @import '../../../node_modules/codemirror/addon/hint/show-hint';
  @import '../../../node_modules/codemirror/addon/dialog/dialog';
 
  @import 'lib/tui/tui-editor';
  @import 'lib/tui/tui-editor-contents';
  @import 'lib/tui/tui-color-picker';
  @import 'lib/rome/rome';
  @import 'lib/gradX/gradX';
  @import 'lib/pivot-grid/pivot.view';
  @import 'lib/jqtree/jqtree';

  ul {
    padding: 0;
    margin: 0;
  }
}
@import 'lib/at/jquery.atwho';

@font-face {
  font-family: 'SpoqaHanSans';
  src: url('/assets/bi/fonts/SpoqaHanSans-Bold.eot');
  src: url('/assets/bi/fonts/SpoqaHanSans-Bold.eot?#iefix') format('embedded-opentype'),
    url(/assets/bi/fonts/SpoqaHanSans-Bold.woff) format('woff'),
    url(/assets/bi/fonts/SpoqaHanSans-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SpoqaHanSans';
  src: url('/assets/bi/fonts/SpoqaHanSans-Regular.eot');
  src: url('/assets/bi/fonts/SpoqaHanSans-Regular.eot?#iefix') format('embedded-opentype'),
    url(/assets/bi/fonts/SpoqaHanSans-Regular.woff) format('woff'),
    url(/assets/bi/fonts/SpoqaHanSans-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SpoqaHanSans';
  src: url('/assets/bi/fonts/SpoqaHanSans-Thin.eot');
  src: url('/assets/bi/fonts/SpoqaHanSans-Thin.eot?#iefix') format('embedded-opentype'),
    url('/assets/bi/fonts/SpoqaHanSans-Thin.woff') format('woff'),
    url('/assets/bi/fonts/SpoqaHanSans-Thin.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

.datepickers-container {
  z-index: 1000;
}

// Theme Font
@import 'styles/scss/themes/font/theme-font';
// icon
@import 'styles/scss/themes/font/datta-icon';
@import 'styles/scss/themes/font/feather';
@import 'styles/scss/themes/font/fontawesome';
@import 'styles/scss/themes/font/cryptofont';

@import 'assets/css/feather';

body.body-hidden {
  overflow: hidden;
}
